// light
theme-light() {
    --brand: $brand;

    --black-primary:   $light-black-primary;
    --black-secondary: $light-black-secondary;

    --border-primary: $light-border-primary;

    --gray-primary:   $light-gray-primary;
    --gray-secondary: $light-gray-secondary;
    --gray-tertiary:  $light-gray-tertiary;
    
    --blue-primary: $light-blue-primary;
    --green-primaty: $light-green-primary;
    --reg-primary:   $light-red-primary;

    --background-primary:   $light-background-primary;
    --background-secondary: $light-background-secondary;
}

// dark
theme-dark() {
    --brand: #000;

    --black-primary:   $dark-black-primary;
    --black-secondary: $dark-black-secondary;

    --border-primary: $dark-border-primary;
    
    --gray-primary:   $dark-gray-primary;
    --gray-secondary: $dark-gray-secondary;
    --gray-tertiary:  $dark-gray-tertiary;
    
    --blue-primary: $light-blue-primary;
    --green-primaty: $light-green-primary;
    --reg-primary:   $light-red-primary;

    --background-primary:   $dark-background-primary;
    --background-secondary: $dark-background-secondary;
}


// root
:root {
    theme-light();
    &[data-color-scheme="dark"] {
        theme-dark();
    }
}