button, .button
	font-size: 16px
	font-weight: bold
	font-family: $font-sans
	text-decoration: none
	cursor: pointer
	border: 2px solid transparent
	background-color: transparent
	
	display: flex
	align-items: center
	justify-content: center
	
	transition: all $transition-primary
	&:hover
		hover()
	&:active
		active()
	.icon
		height: 32px
		width: 32px
		min-width: 32px
		&--left
			margin: 0px 8px 0px 0px	

button
	padding: 0px
	-webkit-appearance: none

.button
	padding: 5px 25px
	border-radius: 100px
	&--brand
		fill: #fff
		color: #fff
		background-color: $brand
		background-color: var(--brand)
		[data-color-scheme="dark"] &
			fill: $brand  // ie
			color: $brand // ie

			fill: var(--brand);
			color: var(--brand);
			border-color: #fff;
			background-color: #fff;
	&--blue
		fill: #fff
		color: #fff
		background-color: $light-blue-primary
		background-color: var(--blue-primary)