.work
    &__section
        & + ^[0]__section
            margin: 80px 0px 0px
    &__title
        font-size: 22px
        font-weight: bold
        margin: 0px 0px 15px
        scroll-margin-top: 65px
    &__row
        margin: -10px
        display: flex
        flex-wrap: wrap
    &__col
        margin: 10px        
        flex-basis: calc((100% / 4) - 20px)
    &-nav
        display: flex
        align-items: center
        justify-content: center
        
        top: 0px
        position: sticky

        z-index: 2
        font-size: 14px
        overflow-x: auto
        padding: 5px 0px
        margin: 0px 0px 20px
        scroll-shadow-x(var(--background-primary))
        &__item
            white-space: nowrap
            & + ^[-1]__item
                margin: 0px 0px 0px 10px
    &-item                
        display: flex
        flex-direction: column
        
        fill: #fff
        min-height: 500px          
        background: $brand
        background: var(--work-brand-color)
        &:hover
            hover()
        &__main
            display: flex
            align-items: center
            justify-content: center

            padding: 15px
            min-height: 320px
        &__logo
            width: 128px
            height: 128px
            pointer-events: none
        &__name
            color: #fff
            font-size: 24px
            font-weight: bold
            text-align: center       
            min-height: 128px
            display: flex
            align-items: center
        &__footer
            display: flex
            flex: 1 0 auto                       
            flex-direction: column

            padding: 15px
            margin: 0px 2px 2px
            background-color: $dark-background-secondary
            background-color: var(--background-secondary)
        &__desc
            flex: 1 0 auto
            p
                margin: 0px
        &-stack
            font-size: 14px
            margin: 15px 0px 0px
            &__row
                margin: -3px
                display: flex
                flex-wrap: wrap
            &__col
                margin: 3px
                padding: 3px 15px
                text-align: center
                line-height: normal
                border-width: 1px
                border-style: solid
                border-color: #cdcdcd
                border-color: var(--border-primary)
    

    // <= 1280px
    +media-desktop-s()
        &__col
            flex-basis: calc((100% / 3) - 20px)

    // <= 768px
    +media-table-s()
        &__section
            & + ^[0]__section
                margin: 50px 0px 0px
        &__row
            padding: 5px 20px
            margin: 0px -20px
            overflow-x: auto
            flex-wrap: nowrap
            scroll-shadow-x(var(--background-primary))
        &__col
            margin: 0px
            min-width: 270px
            & + ^[-1]__col
                margin: 0px 0px 0px 15px
        &-nav
            padding: 5px 20px
            margin-right: -20px
            margin-left: -20px
            justify-content: flex-start
            // &__item
            //     flex-grow: 1