/* --- Popup --- */
.html-popup {
	overflow: hidden;
}

.popup {
	top: 0px;
	right: 0px;
	left: 0px;
	bottom: 0px;
	position: fixed;
	
	height: 100vh;
	padding: 20px;
	z-index: 999;
	cursor: pointer;
	background-color: rgba(19, 19, 19, 0.856);
	
	display: flex;
	align-items: center;
	justify-content: center;

	opacity 0;
	visibility hidden;
	&-modal {
		display: flex;
		flex-direction: column;

		cursor: default;
		width: 100%;
		max-width: 348px;
		max-height: 100%;
		position: relative;
		border-radius: $radius-primary;
		background-color: $dark-background-primary;
		background-color: var(--background-primary);
		box-shadow: $box-shadow-secondary;

		transform: translateY(5px);
		transition: transform $transition-primary;
		.popup--show & {
			transform: translateY(0px);
		}
	}

	&-content {
		overflow-y: auto;
		padding: 35px 20px 20px;	
	}

	&-close {
		top: 0;
		right: 0;
		position: absolute;

		display: flex;
		align-items: center;
		justify-content: center;

		padding: 0;
		width: 35px;
		height: 35px;
		cursor: pointer;
		fill: $light-red-primary;
		fill: var(--reg-primary);
		border-radius: $radius-primary;
		svg {
			width: 16px;
			height: 16px;
			pointer-events: none;
		}
	}

	&--show {
		opacity: 1;
		visibility: visible;	
	}

	&--hide {
		opacity: 0;
		visibility: hidden;	
	}				
}