.nav
    &__row
        margin: -5px
        display: flex
    &__item
        margin: 5px
        min-height: 46px
        font-size: 18px
        white-space: nowrap
    
    // <= 650px
    +media-phone-m()
        &__row
            flex-direction: column
        &__item
            font-size: 16px