*
    box-sizing: border-box

html, body
    scrollbar-gutter: stable

html
    min-width: 320px
    overflow-x: auto

body
    font-family: $font-sans
    font-size: $text-base
    line-height: 1.5
    color: $dark-black-secondary // ie
    color: var(--black-secondary)
    text-rendering: optimizeSpeed

    display: flex
    flex-direction: column
    
    margin: 0px
    min-height: 100vh
    background-color: $dark-background-primary
    background-color: var(--background-primary)

.main
    flex: 1 0 auto    

.container
    width: 100%
    max-width: 1200px
    margin: 0px auto
    &-s
        width: 100%
        max-width: 768px
        margin: 0px auto

.section
    padding: 80px 20px    
    // <= 980px
    +media-table-m()
        padding: 50px 20px


.font
    &-serif
        font-family: $font-serif

.text
    &-lg
        font-size: $text-lg
        // <= 480px
        +media-phone-s()
            font-size: 16px
