h1, h2, h3, h4, h5, h6, .title
	font-size: 32px
	line-height: normal
	margin: 0px 0px 25px
	color: $dark-black-primary
	color: var(--black-primary)

.subtitle
	font-size: 20px
	font-weight: bold
	margin: 50px 0px 20px

p, ul, ol
	margin: 0px
	&:not([class])
		margin: 20px 0px

ul, ol
	[class]
		padding-left: 0px		
		list-style-type: none
	&:not([class])
		li
			position: relative
			& + li
				margin: 10px 0px 0px

a
	color: inherit
	text-decoration: none
	transition: all $transition-primary
	&:not([class])
		color: $light-blue-primary
		text-decoration: underline
		&:hover
			text-decoration: none	
	&:active
		active()

code:not([class])
	font-family: Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace
	font-size: 14px
	color: $light-gray-primary
	white-space: nowrap
	padding: 1.5px 5px
	border-radius: $radius-primary
	background-color: #000
	background-color: var(--brand)
	[data-color-scheme="dark"] &
		color: $light-black-primary
		background-color: #fff


// <= 650px
+media-phone-m()
	h1, h2, h3, h4, h5, h6, .title
		font-size: 28px
		margin: 0px 0px 15px
	
	.subtitle
		font-size: 18px
