.logotype
    width: 280px
    height: 280px
    display: flex
    align-items: center
    justify-content: center
    position: relative
    border-radius: 100%
    background-color: $brand
    background-image: var(--color-logotype)
    &__pic
        width: 260px
        height: 260px
        display: block
        border-radius: 100%
    &-sleeps
        font-size: 21px    
        font-weight: bold
        &__item
            opacity: 0
            left: 0px
            bottom: 50%
            position: absolute
            animation: AnimeSleeps $transition-animation infinite linear
            &:nth-of-type(1)
                animation-delay: .5s
            &:nth-of-type(2)
                animation-delay: 1.5s
    
    // <= 380px
    +media-phone-xs()
        width: 220px
        height: 220px
        &__pic
            width: 200px
            height: 200px


@keyframes AnimeSleeps {
    
    from {
        opacity: 1
        transform: translate3d(0px, 0px, 0px)
    }

    50% {
        opacity: 1
        transform: translate3d(-12px, -50px, 0px)
    }

    to {
        opacity: 0
        transform: translate3d(6px, -100px, 0px)
    }

}