.contacts
    &__trigger
        fill: $brand
        display: none     
        padding: 10px        
        border-radius: $radius-primary
        svg
            width: 32px
            height: 32px
        // <= 980px
        +media-table-m() {
            display: flex
        }

    &__nav
        display: flex
        flex-wrap: wrap
        align-items: center
        font-weight: bold    
        margin: -10px
        // <= 980px
        +media-table-m() {
            &.cn--desktop {
                display: none        
            }
        }
    &__item
        margin: 10px            
        display: flex
        align-items: center
        &:hover
            text-decoration: underline
    &__icon
        width: 32px
        height: 32px
        margin: 0px 10px 0px 0px