active()
	transform: translateY(2px)

hover()
	filter: contrast(0.8)

scroll-shadow-x(color)
	background-image: 
		linear-gradient(to right, color, color), 
		linear-gradient(to right, color, color),
		linear-gradient(to right, rgba(0,0,0,.25), rgba(255,255,255,0)),
		linear-gradient(to left, rgba(0,0,0,.25), rgba(255,255,255,0));   
	background-position: left center, right center, left center, right center;
	background-repeat: no-repeat;
	background-color: color;
	background-size: 20px 100%, 20px 100%, 10px 100%, 10px 100%;	
	background-attachment: local, local, scroll, scroll;


/* desktop */

// <= 1440px
media-desktop-m(resolution = $lg_m)	
	@media screen and (max-width: resolution) 
		{block}

// <= 1280px
media-desktop-s(resolution = $lg_s)
	@media screen and (max-width: resolution) 
		{block}

// <= 1024px
media-desktop-xs(resolution = $lg_xs)	
	@media screen and (max-width: resolution) 
		{block}

/* tablet */

// <= 980px
media-table-m(resolution = $medium_m)		
	@media screen and (max-width: resolution) 
		{block}	

// <= 768px
media-table-s(resolution = $medium_s)			
	@media screen and (max-width: resolution) 
		{block}

/* phone */

// <= 650px
media-phone-m(resolution = $small_m)	
	@media screen and (max-width: resolution) 
		{block}	

// <= 480px
media-phone-s(resolution = $small_s)	
	@media screen and (max-width: resolution) 
		{block}	

// <= 380px
media-phone-xs(resolution = $small_xs)	
	@media screen and (max-width: resolution) 
		{block}	