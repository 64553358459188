.welcome
    display: flex
    align-items: center

    min-height: 100vh
    background: url('../images/logotypes-stack.png') center no-repeat
    background-size: contain
    &__container
        display: flex
        align-items: center
        flex-direction: column
    .logotype
        margin: 0px 0px 25px
    
    // <= 650px
    +media-phone-m()
        background-size: cover